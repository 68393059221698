<template>
  <div class="header">
    <div class="logo">
      <router-link to="/">
        <img src="../../assets/imgs/logo.png" alt="" />
      </router-link>
    </div>
    <div class="nav" v-if="!ifHideNav">
      <div class="nav-card">
        <!-- <div class="nav-card-item">
          <el-icon @click="openMessageDialog"><InfoFilled /></el-icon>
        </div> -->
        <!--  currentRoute 1：user路由  2:project路由以及其他路由 -->
        <div
          v-for="(navCardItem, navCardItemIndex) in navList"
          :key="navCardItemIndex"
          :class="[
            'nav-card-item',
            navCardItemIndex == navItemIndex &&
            navCardItem.isActive &&
            currentRoute == 2
              ? 'nav-active'
              : '',
          ]"
          @click="navCardItem.handleClick(navCardItemIndex)"
        >
          <i :class="['iconfont', navCardItem.icon]"></i>
          <span class="nav-card-item-name">{{ navCardItem.name }}</span>
        </div>
      </div>
      <div class="user-message">
        <i
          :class="[
            'iconfont',
            'icon-gerenzhongxin',
            currentRoute == 1 ? 'user-active' : '',
          ]"
        ></i>
        <el-dropdown class="user-message-dropdown">
          <span :class="[currentRoute == 1 ? 'user-active' : '']">
            <span v-if="name">{{ name }}</span>
            <span v-else>个人中心</span>
            <el-icon class="el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="goUserCenter">
                个人中心
              </el-dropdown-item>
              <el-dropdown-item @click="goPersonalRights">
                个人权益
              </el-dropdown-item>
              <el-dropdown-item @click="logOut">退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <!-- <el-dialog
      v-model="newVersionDLGVisible"
      title="提示"
      :modal="false"
      width="600px"
    >
      <div style="line-height: 30px; font-size: 16px; text-align: left">
        <p>尊敬的锐研·云统计用户，您好！</p>
        <p style="text-indent: 2em; line-height: 26px; text-align: justify">
          为了更好地支持用户进行统计分析，我们已于2023年9月21日发布V3.0新版；新版本云统计优化了分析算法模型、分析结果可视化，增加了数据处理功能，用户体验全面提升，如您在使用新版本过程中有任何问题，请扫描下方二维码添加客服微信咨询！感谢您的使用与支持！
          截止至2023年12月31日，我们将依旧支持旧版云统计的访问与使用，地址为：
          <a href="https://tj.ringdata.com/oldversion/" style="color: blue">
            https://tj.ringdata.com/oldversion/
          </a>
        </p>
        <div style="display: flex; justify-content: space-around">
          <img
            src="../../assets/imgs/customerQRCode.jpg"
            width="180"
            height="180"
            style="margin: auto; margin-top: 20px"
          />
        </div>

        <p align="right" style="line-height: 30px">锐研数据</p>
        <p align="right" style="line-height: 30px">2023年9月21日</p>
      </div>
    </el-dialog> -->
  </div>
</template>

<script setup>
import { ref, defineProps, watch, getCurrentInstance, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { httpPost } from "@/api/httpService.js";
import VueCookies from "vue-cookies";

// import router from "../../router";//TODO
import { useUserInfo } from "@/utils/useUserInfo";
const route = useRoute();
const router = useRouter();
const { proxy } = getCurrentInstance();
const { name } = useUserInfo();

const props = defineProps({
  ifHideNav: {
    default: false,
  },
});
const navItemIndex = ref("");

const navList = ref([
  {
    name: "我的数据集",
    isActive: true,
    icon: "icon-wodeshujuji",
    handleClick: (navCardItemIndex) => {
      navItemIndex.value = navCardItemIndex;
      router.push("/project");
    },
  },
  {
    name: "帮助中心",
    handleClick: () => {
      window.open("https://help.ringdata.com/project/tongji");
    },
    isActive: false,
    icon: "icon-bangzhuzhongxin",
  },
]);
const currentRoute = ref(null);

watch(
  route,
  (route, oldRoute) => {
    if (route.path.indexOf("/user") != -1) {
      currentRoute.value = 1;
    } else {
      currentRoute.value = 2;
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

const isHighLight = ref(true);
//前往锐研个人中心
const goUserCenter = () => {
  window.open("https://i.ringdata.com/index", "_blank");
};
//个人权益
const goPersonalRights = () => {
  // navList.value.forEach((item) => {
  //   item.isActive = false;
  // });
  router.push("/user");
};
//退出
const logOut = async () => {
  let res = await httpPost("/centerAuth/v1/exit");
  let { userId } = VueCookies.get("userInfo");
  if (res.code == 0) {
    VueCookies.remove("tokenWeb");
    VueCookies.remove("userInfo");
    VueCookies.remove("tokenWebRefresh");
    VueCookies.remove("tokenWeb", "/", "ringdata.com");
    VueCookies.remove("userInfo", "/", "ringdata.com");
    VueCookies.remove("tokenWebRefresh", "/", "ringdata.com");
    proxy.message.success("退出成功");
    window.sessionStorage.setItem("ifLoginSTAT_" + userId, false);
    setTimeout(() => {
      router.push("/");
    }, 1000);
  }
};

// const newVersionDLGVisible = ref(false);
// const openMessageDialog = () => {
//   newVersionDLGVisible.value = true;
// };
// const showNewVersionNotification = () => {
//   newVersionDLGVisible.value = true;
// };
onMounted(() => {
  // if (!window.localStorage.getItem("TJ_ifShowedNewVersionNotification")) {
  //   showNewVersionNotification();
  //   window.localStorage.setItem("TJ_ifShowedNewVersionNotification", true);
  // }
});
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  position: relative;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: $header-height;
  color: #6c6c6c;
  font-size: 14px;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(206, 206, 206, 0.4);
  .logo {
    // width: 230px;
    height: 40px;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    .iconfont {
      margin-right: 10px;
    }

    .nav-card {
      display: flex;
      height: 60px;
      .nav-card-item {
        display: flex;
        align-items: center;
        margin: 0 15px;
        height: 60px;
        box-sizing: border-box;
      }
      .nav-card-item:hover {
        cursor: pointer;
        color: #1a78ff;
      }
    }
    .user-message {
      color: #6c6c6c;
      height: 60px;
      display: flex;
      align-items: center;
      margin-right: 20px;
      margin-left: 15px;

      .user-message-dropdown {
        display: flex;
        align-items: center;
      }
      .user-message-dropdown:hover {
        color: #1a78ff;
        cursor: pointer;
      }
    }
    .user-message:hover {
      color: #1a78ff;
      cursor: pointer;
    }
  }
  .nav-active {
    border-bottom: 1px solid rgba(26, 120, 255, 100);
    color: #1a78ff;
    box-sizing: border-box;
  }
  .user-active {
    color: #1a78ff;
  }
}
</style>
