<template>
  <div class="login-container">
    <div class="login-header">
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/imgs/logo.png" alt="" />
        </router-link>
      </div>
    </div>
    <div class="login-content">
      <div class="login-image"></div>
      <el-card class="login-card">
        <template #header>
          <div class="card-header">
            <div class="card-header-text">欢迎使用云统计</div>
          </div>
        </template>
        <div class="login-switch-tabs">
          <el-tabs
            v-model="activeName"
            class="switch-tabs"
            @tab-click="handleClick"
          >
            <el-tab-pane
              label="账号登录"
              name="login-account"
              class="login-account"
            >
              <el-form
                :model="loginForm"
                :rules="loginRules"
                ref="refLoginForm"
                class="login-form"
              >
                <el-form-item prop="loginName">
                  <el-input
                    v-model="loginForm.loginName"
                    placeholder="请输入手机号/邮箱/用户名"
                    type="text"
                  >
                    <template #prefix>
                      <i class="iconfont icon-dengluyonghu"></i>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input
                    v-model="loginForm.password"
                    placeholder="请输入密码"
                    :type="loginForm.passwordFlagType"
                  >
                    <template #prefix>
                      <i class="iconfont icon-denglumima"></i>
                    </template>
                    <template #suffix>
                      <i
                        :class="[
                          loginForm.passwordFlag
                            ? 'iconfont icon-mimakejian'
                            : 'iconfont icon-mimabukejian',
                        ]"
                        @click="changePasswordFlag"
                      ></i>
                    </template>
                  </el-input>
                </el-form-item>
                <div class="login-morechoice">
                  <div>
                    <el-checkbox v-model="loginForm.rememberMe" size="large">
                      记住我
                    </el-checkbox>
                  </div>
                  <span href="" @click="getPassword">忘记密码</span>
                </div>
              </el-form>
              <div class="login-btn">
                <el-button
                  :disabled="isDisabled"
                  type="primary"
                  @click="submitForm('refLoginForm')"
                >
                  {{ btnName }}
                </el-button>
              </div>
              <div class="login-register">
                <p>
                  还没有账号？
                  <span @click="toRegister">立即注册</span>
                </p>
              </div>
            </el-tab-pane>
            <!-- TODO -->
            <!-- <el-tab-pane label="验证码登录" name="login-verification">
              验证码登录
            </el-tab-pane> -->
          </el-tabs>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import BaseHeader from "@/components/layout/BaseHeader.vue";
import { useRouter } from "vue-router";
import { Debounce } from "@/utils/utils";
import crypto from "crypto";
import { httpPost, httpPostToken } from "@/api/httpService";
import { ref, reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import { ElMessage } from "element-plus";
import VueCookies from "vue-cookies";
import { loadIframe } from "@/utils/loadIframe.js";

export default {
  name: "login",
  components: { BaseHeader },
  setup() {
    // 选择登录方式
    const activeName = ref("login-account");
    const handleClick = (tab, event) => {};

    // 登录表单
    const router = useRouter();
    const instance = getCurrentInstance();

    /**数据 start */
    const refLoginForm = ref(null);
    let data = reactive({
      loginForm: {
        loginName: "",
        password: "",
        rememberMe: true,
        passwordFlag: false,
        passwordFlagType: "password",
      },
      loginRules: {
        loginName: [
          {
            required: true,
            message: "请输入手机号/邮箱/用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      isDisabled: false,
      btnName: "登录",
      btnLoading: false,
    });
    /**数据 end */
    data.loginForm.loginName = window.localStorage.getItem("loginName");
    data.loginForm.password = window.localStorage.getItem("userPassword");

    const changePasswordFlag = () => {
      data.loginForm.passwordFlag = !data.loginForm.passwordFlag;
      data.loginForm.passwordFlagType = data.loginForm.passwordFlag
        ? "text"
        : "password";
    };

    function toRegister() {
      // router.push("/register");
    }
    function getPassword() {
      // router.push("/findpassword");
      // window.open("https://www.ringdata.com/findpass");
    }
    // 成功登录返回值
    // const loginSuccessfulData = ref({});
    // 记住账号选项
    const submitForm = Debounce(function () {
      refLoginForm.value.validate((valid) => {
        if (valid) {
          var ip = window.localStorage.getItem("ip");
          var city = window.localStorage.getItem("city");
          var client = "STAT";
          data.isDisabled = true;
          data.btnLoading = true;
          data.btnName = "登录中";
          if (data.loginForm.password.toString().length != 32) {
            const md5 = crypto.createHash("md5");
            md5.update(data.loginForm.password);
            data.loginForm.password = md5.digest("hex");
          }
          httpPostToken(
            "/oauth/token",
            `grant_type=password&username=${data.loginForm.loginName}&password=${data.loginForm.password}&clientType=${client}&ip=${ip}&city=${city}`
          )
            .then((res) => {
              if (res) {
                //缓存用户名密码
                if (data.loginForm.rememberMe) {
                  window.localStorage.setItem(
                    "loginName",
                    data.loginForm.loginName
                  );
                  window.localStorage.setItem(
                    "userPassword",
                    data.loginForm.password
                  );
                } else {
                  window.localStorage.setItem("loginName", "");
                  window.localStorage.setItem("userPassword", "");
                }
                // 嵌入iframe实现免登录
                loadIframe("https://www.ringdata.com/freeLogin");

                // let userInfo = VueCookies.get("userInfo");
                httpPost("/user/v1/permission", null)
                  .then((res) => {
                    if (res && res.code === 0) {
                      // userInfo.nlpPermission = res.data;
                      // userInfo.ifLoginRS = true;
                      // this.setLoginStatus({
                      //   status: Date.now()
                      // })
                      // VueCookies.set("userInfo", userInfo);
                      setTimeout(() => {
                        router.replace({
                          path: "/project",
                        });
                      }, 500);

                      data.isDisabled = false;
                      data.btnLoading = false;
                      data.btnName = "登录";

                      // res.data.forEach((value, key) => {
                      //   loginSuccessfulData.value[key] = value;
                      // });
                    } else {
                      router.push("/login");
                      data.isDisabled = false;
                      data.btnLoading = false;
                      data.btnName = "登录";
                    }
                  })
                  .catch((err) => {
                    data.isDisabled = false;
                    data.btnLoading = false;
                    data.btnName = "登录";
                  });
              } else if (res) {
                data.isDisabled = false;
                data.btnLoading = false;
                data.btnName = "登录";
                ElMessage.error(res.message);
              }
            })
            .catch((err) => {
              data.isDisabled = false;
              data.btnLoading = false;
              data.btnName = "登录";
            });
        } else {
          return false;
        }
      });
    });

    return {
      // data,
      refLoginForm,
      ...toRefs(data),
      toRegister,
      getPassword,
      changePasswordFlag,
      handleClick,
      submitForm,
      activeName,
      // loginSuccessfulData,
    };
  },
};
</script>

<style lang="scss" scoped>
.login-header {
  display: flex;
  position: relative;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: $header-height;
  color: #6c6c6c;
  font-size: 14px;
  text-align: center;
  .logo {
    // width: 230px;
    height: 40px;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.login-content {
  width: 100vw;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: space-between;
  .login-image {
    position: relative;
    top: calc(100vh / 2 - 45vh);
    height: 80vh;
    width: 65vw;
    position: relative;
    background-image: url("~@/assets/imgs/登录页面图片.png");
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .login-card {
    position: relative;
    top: calc(100vh / 2 - 570px / 2);
    right: 10vw;
    width: 440px;
    min-width: 440px;
    height: 510px;
    color: rgba(16, 16, 16, 1);
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(206, 206, 206, 0.4);
    :deep(.el-card__header) {
      padding: 40px 30px;
      padding-bottom: 32px;
      border-bottom: 1px solid transparent;
      .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .card-header-text {
          width: 168px;
          height: 36px;
          color: rgb(0, 0, 0);
          font-size: 24px;
          font-weight: 700;
          text-align: left;
        }
      }
    }

    :deep(.el-card__body) {
      padding: 0px 30px 40px 30px;
      overflow: inherit;
    }
    .login-switch-tabs {
      .switch-tabs {
        .login-account {
          :deep(.el-input__wrapper:hover) {
            border: 1px solid rgba(220, 220, 220, 1);
            box-shadow: 0px 0px 6px 2px rgba(206, 206, 206, 0.5);
          }
          :deep(.el-input__wrapper:focus) {
            border: 1px solid rgba(220, 220, 220, 1);
            box-shadow: 0px 0px 6px 2px rgba(206, 206, 206, 0.5);
          }
          :deep(.is-focus) {
            border: 1px solid rgba(220, 220, 220, 1);
            box-shadow: 0px 0px 6px 2px rgba(206, 206, 206, 0.5);
          }
          .login-form {
            :deep(.el-form-item) {
              margin-bottom: 0px;
              margin-top: 24px;
            }
            :deep(.el-input__wrapper) {
              height: 40px;
              font-size: 14px;
              border-radius: 8px;
              border-radius: 8px;
            }
            .login-morechoice {
              margin-top: 32px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              a,
              span {
                color: $theme-color;
              }
              :deep(.el-checkbox) {
                // width: 20px;
                // height: 20px;
                color: $theme-color;
              }
            }
          }
        }
        .login-btn {
          width: 100%;
          margin-top: 32px;
          :deep(.el-button) {
            height: 40px;
            width: 100%;
            border-radius: 8px;
            font-size: 14px;
          }
        }
        .login-register {
          margin-top: 32px;
          display: flex;
          justify-content: center;
          span {
            color: $theme-color;
          }
        }
      }
      .login-verification {
      }
    }
    :deep(.el-tabs__header) {
      margin-bottom: 0px;
    }
    :deep(.el-tabs__item) {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;
      height: 24px;
      line-height: 24px;
    }
  }
  :deep(.el-card) {
    border: 1px solid transparent;
  }
}
</style>
