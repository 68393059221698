export function loadIframe(src) {
  let iframe;
  try {
    iframe = document.createElement(
      `<iframe style="display:none" src="${src}"></iframe>`
    );
    document.body.appendChild(iframe);
  } catch (e) {
    // console.log(e);
    iframe = document.createElement("iframe");
    iframe.name = "ifr";
    iframe.src = src;
    iframe.style = "display:none";
    document.body.appendChild(iframe);
  }
}
